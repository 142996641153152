.receive-assets{
  &__wrap {
    display: flex;
    flex-direction: column;
  }
  &__text{
    font-size: (14rem / 16);
    line-height: (22rem / 16);
    margin-bottom: 8px;
  }
  &__select {
    width: 100% !important;
    .ant-select-selector{
      padding: 0 12px !important;
    }
  }
  &__input{
    margin-right: 16px !important;
  }
  &__address{
    display: flex;
  }
  &__button{
    margin: 40px 0 0;
    width: 100%;
  }
}
