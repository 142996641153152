@import "~scss/utils/_var";
@import "~scss/utils/_mixins";

.transactions-info {
  padding: 26px 50px;

  &__row {
    display: flex;
    @include colGap(14px);

    &__item {
      @include rowGap(50px);

      &__action {
        margin-top: 4px;

        button {
          padding: 2px 8px;
          margin-left: 10px;
        }
      }

      &__text {
        margin-top: 12px;
      }
    }
  }
}