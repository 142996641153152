@media (min-width: 320px) {
  .load-coin-large {
    width: 52px;
    height: 77px;
  }
}
@media (min-width: 768px) {
  .load-coin-large {
    width: 68px;
    height: 101px;
  }
}
