.favorites {
  &__star{
    text-align: center;
  }
  &__button{
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    &:focus{
      outline: none;
    }
  }
  &__modal-body{
    //overflow-x: hidden;
  }
  &__value{
    font-size: 14px;
    line-height: 22px;
  }
  &__table-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:48px 0 20px;
    .ant-input,
    .ant-input-search-button{
      &:hover,
      &:focus{
        border-color: #554cc2 !important;
        outline: none;
      }
    }
  }
}
.row-dragging {
  z-index: 1001;
  background: #fafafa !important;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  background: transparent;
}

.row-dragging .drag-visible {
  visibility: visible !important;
}
