@import "~scss/utils/_var";
@import "~scss/utils/mixins";

.portfolio-transactions {
  background-color: $cDefault;
  height: 785px;
  border: 1px solid #EBEDF1;
  border-radius: 2px;

  .ant-spin-container{
    display: flex;
    flex-direction: column;
  }
  .ant-table{
    flex: 1;
  }

  &__header {

    padding: 18px 24px;

    &_subheader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_filter {
      margin-top: 16px;
      background: #FBFBFC;
      border: 1px dashed #EBEDF1;
      border-radius: 2px;
      padding: 12px 20px;

      &:before {
        content: 'Applied filters:';
        position: relative;
        top: 0;
        left: 0;
        margin-right: 16px;
      }

      &_item {
        padding: 6px 10px !important;
        margin-right: 12px !important;
        margin-top: 12px !important;
        color: $cPrimary !important;

        .ant-tag-close-icon {
          margin-left: 10px;
        }

        svg {
          font-size: 12px;
        }
      }
    }

    &__action {

    }
  }

  &__table {
    height: calc(100% - 90px);
    width: 100%;

    .status{
      display: inline-block;
      padding: 1px 8px;
      border-radius: 2px;
      font-size: (12rem / 16) !important;
      line-height: (20rem / 16) !important;
      text-transform: capitalize !important;
      &.processed{
        background-color: #E2FBEB;
        color: #358F54;
      }
      &.waiting{
        background-color: #FFF7E6;
        color: #FA8C16;
      }
      &.canceled{
        background-color: #FFF1F0;
        color: #D23E50;
      }
    }

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        .ant-table {
          //height: calc(100% - 50px);

        }
      }
    }

    &__item {

      &__amount {
      }

      &__type {
        display: flex;
        align-items: center;

        p {
          margin-left: 12px;
        }

        &_down {
          color: $cPurple;
          transform: rotate(45deg);
        }

        &_up {
          color: $cPurple;
          transform: rotate(-135deg);
        }
      }

      &__currency {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 24px;
    }
  }
}
