.help{
  padding: 24px;
  &__panels{
    max-width: 816px;
  }
  &__text{
    p{
      font-size: (14rem / 16);
      line-height: (22rem / 16);
      margin-bottom: 24px;
    }
    a{
      color: #554CC2;
      &:hover{
        color: #554CC2;
        text-decoration: underline;
      }
    }
  }
}