@import "../../configComponents/parentComponent/creator";

@import "vars";
@import "maker";

.c-push{
  position: relative;
  border-radius: 4px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 6px;
    background-color: #52C41A;
  }
}
