.icon-404 {

}

@media (min-width: 320px) {
  .icon-404 {
    width: 270px;
    height: 100px;
  }
}
@media (min-width: 768px) {
  .icon-404 {
    width: 380px;
    height: 142px;
  }
}
@media (min-width: 992px) {
  .icon-404 {
    width: 380px;
    height: 142px;
  }
}
@media (min-width: 1200px) {
  .icon-404 {
    width: 380px;
    height: 142px;
  }
}
@media (min-width: 1440px) {
  .icon-404 {
    width: 380px;
    height: 142px;
  }
}
@media (min-width: 1600px) {
  .icon-404 {
    width: 450px;
    height: 168px;
  }
}