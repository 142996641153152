@import "~scss/utils/_var";
@import "~scss/utils/_mixins";

.subscribe-form {

  &__input {
    @include styleAllScreen(margin-top, 15px, 15px, 15px, 15px, 22px, 15px,);

    input {
      color: $cPrimary;
      border-color: $cPrimary;
      font-size: 16px;
      height: 49px;
      border-right: none;
    }

    button {
      width: 52px !important;
      height: 49px !important;
      background-color: $cSecondary !important;
      transition: .3s;

      &:focus,
      &:hover {
        color: $cDefault !important;
        background-color: #655BE3 !important;
        transition: .3s;
      }
      &:active {
        color: $cDefault !important;
        background-color: #493FBF !important;
        transition: .3s;
      }
    }

    .ant-input-group-addon {
      padding: 0;
      border: none;
    }
  }
}


@media (min-width: 768px) {
  .subscribe-form {

    &__input {
      width: 320px !important;

      input {
        width: 280px !important;
      }
    }
  }
}
@media (min-width: 992px) {
  .subscribe-form {

    &__input {
      width: 320px !important;

      input {
        width: 280px !important;
      }
    }
  }
}
@media (min-width: 1200px) {
  .subscribe-form {

    &__input {
      width: 320px !important;

      input {
        width: 280px !important;
      }
    }
  }
}
@media (min-width: 1440px) {
  .subscribe-form {

    &__input {
      width: 320px !important;

      input {
        width: 280px !important;
      }
    }
  }
}
@media (min-width: 1600px) {
  .subscribe-form {
    &__input {
      width: 340px !important;

      input {
        width: 300px !important;
      }
    }
  }
}