@import "~scss/utils/_var";
@import "~scss/utils/mixins";


.portfolio-overview {
  &__correlation-body{
    .node-error{
      padding: 20px;
    }
  }
  &__info {
    display: flex;
    margin-bottom: 26px;
    height: 250px;

    &__about {
      //width: 800px;
      flex: 2;
      margin-right: 13px;
      background-color: $cDefault;
      border: 1px solid #EBEDF1;
      border-radius: 2px;

      &__title {
        padding: 16px 24px;
        box-shadow: inset 0 -1px 0 #F3F2F5;
      }

      &__body {
        height: calc(100% - 105px);
        padding: 16px 24px;
        overflow-y: auto;
      }

      &__footer {
        padding: 16px 24px;
        box-shadow: inset 0 1px 0 #F3F2F5;
        display: flex;
        align-items: center;

        &__item {
          display: flex;
          align-items: center;
          @include rowGap(22px);

          &__title {

            .ant-skeleton-element {
              margin: 0 !important;
            }

            .ant-skeleton-input {
              margin: 0 !important;
              width: 100px !important;
              height: 20px !important;
            }
          }

          &__icon {
            color: $cSecondary !important;
            margin-right: 8px;
          }
        }
      }
    }

    &__price {
      flex: 1;
      margin-left: 13px;
      background-color: $cDefault;
      width: calc(100% - 800px);
      border: 1px solid #EBEDF1;
      border-radius: 2px;

      &__header {
        padding: 16px 24px;
        box-shadow: inset 0 -1px 0 #f3f2f5;
      }
    }

  }

  &__chart {
    background-color: $cDefault;
    width: 100%;
    height: 352px;
    border: 1px solid #EBEDF1;
    border-radius: 2px;
  }
}
