.node-error{
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img{
    width: 146px;
    margin: 0 auto;
    display: block;
    height: auto;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ant-empty-description{
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    margin: 20px 0 18px;
  }
}
