@import "../../configComponents/parentComponent/creator";

@import "vars";
@import "maker";


.ant-radio-group {
  color: $cSecondary;

  .ant-radio-button-wrapper {
    color: $cSecondary;

    &:hover {
      border-color: $cSecondary !important;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: $cSecondary !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: $cSecondary !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $cSecondary;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba($cSecondary, .1)
  }
}
