.select-recipient{
  &__list{
    list-style-type: none;
    font-size: (14rem / 16);
    line-height: (22rem / 16);
    width: 100%;
    padding: 24px 0;
    margin: 0;
    &-item{

      margin: 0;
    }
    &-button{
      width: 100%;
      border: none;
      background: transparent;
      padding: 12px 0;
      box-shadow: inset 0px -1px 0px #E0E0E5;
      text-align: left;
      &:hover{
        background: rgb(250, 250, 250);
        cursor: pointer;
      }
    }
    &-title{
      font-weight: 500;
      color: #554CC2;
      text-transform: capitalize;
      margin-bottom: 4px;
    }
    &-text{
      color: #7A7790;
      margin-bottom: 0px;
    }
    &-address{
      color: #332E54;
    }
  }
  &__button{
    margin: 20px 0 0;
    width: 100%;
  }
}
