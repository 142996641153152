@import "~scss/utils/_var";

.header-profile {

  &__breadcrumb {
    margin-bottom: 30px !important;

    &__item {
      color: $cSecondary;
      cursor: pointer;

      &.breadcrumb-item-last {
        color: $cTextGray;
        cursor: default;
      }
    }
  }
}
.header-cab__page-header{
    .ant-tabs-tab-active .primary{
      color: $cSecondary;
    }
}
