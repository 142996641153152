.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &_title {
    margin-top: 21px;
  }

  &_subtitle {
    margin-top: 16px;
  }
}