.validate-password-status {
  width: 200px;
  //padding: 12px 18px;

  &__item {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  &__icon {
    margin-right: 10px;
  }

  &__info {
    margin-top: -2px;
  }
}