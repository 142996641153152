.portfolio-add-wallet-modal {

  &__input {
    margin-left: 24px;
  }

  .ant-modal-header{
    border-bottom: 0;
  }

  .ant-modal-body{
    padding: 0;
  }
  &__bottom{
    padding: 16px 24px;
    text-align: right;
  }
  &__link{
    color: #7369e4;
    &:hover,
    &:active{
      color: #7369e4;
      opacity: .8;
    }
  }
}