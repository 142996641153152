@import "~scss/utils/_var";

.sider {
  background-color: $cBgDefault !important;
  border-right: 1px solid $cBorderPrimary;
  position: relative;

  &__logo {
    margin-left: 23px;
    height: 64px;

    .logo__title {
      top: 22px;
      left: 35px;
    }
  }

  &__logo.hide-logo {
    margin-left: 28px;
  }

  &_title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #332E54;
  }

  .ant-menu-item-selected &_title {
    font-weight: 500;
    color: #554CC2;
  }

  &__collapsed-wrapper {
    position: absolute;
    top: calc(100% - 40px);
    left: 0;
    height: 40px;
    width: 100%;
    border-top: 1px solid $cBorderPrimary;
    padding: 0 18px;
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: 18px !important;
    text-transform: capitalize;
  }

  .ant-menu-inline-collapsed {
    width: 79px;
  }
}
.ant-tooltip-inner{
  color: #fff;
  text-transform: capitalize;
  span{
    color: #fff;
    text-transform: capitalize;
  }
}
