@media (min-width: 320px) {
  .logo-icon {
    width: 29px;
    height: 35px;

    &.small {
      width: 26px;
      height: 30px;
    }
  }
}
@media (min-width: 768px) {
  .logo-icon {
    width: 36px;
    height: 42px;

    &.small {
      width: 26px;
      height: 30px;
    }
  }
}
@media (min-width: 992px) {
  .logo-icon {
    width: 36px;
    height: 42px;

    &.small {
      width: 26px;
      height: 30px;
    }
  }
}
@media (min-width: 1200px) {
  .logo-icon {
    width: 36px;
    height: 42px;

    &.small {
      width: 26px;
      height: 30px;
    }
  }
}
@media (min-width: 1440px) {
  .logo-icon {
    width: 36px;
    height: 42px;

    &.small {
      width: 26px;
      height: 30px;
    }
  }
}
@media (min-width: 1600px) {
  .logo-icon {
    width: 38px;
    height: 44px;

    &.small {
      width: 26px;
      height: 30px;
    }
  }
}