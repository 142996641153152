@import "~scss/utils/_var";

.portfolio-wallet {
  background-color: $cDefault;
  width: 100%;
  height: 625px;
  margin-top: 25px;
  overflow-y: hidden;
  border: 1px solid #EBEDF1;
  border-radius: 2px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 24px;

    &__action {

      &_item:not(:first-child) {
        margin-left: 8px;
      }
      &_item:not(:last-child) {
        margin-right: 8px;
      }
      .ant-spin {
        color: $cSecondary;
      }
    }
  }

  &__table {
    height: calc(100% - 56px);
    width: 100%;
    overflow-y: hidden;

    img {
      margin-right: 10px;
    }

    &__currency {
      cursor: pointer;
      transition: .3s;
      display: flex;
      align-items: center;

      &:hover {
        color: $cSecondary;
      }
    }

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        .ant-table {
          height: calc(100% - 50px);

        }
      }
    }
  }
}

