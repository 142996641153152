@media (min-width: 320px) {
  .load-coin-shadow-middle {
    width: 92px;
    height: 82px;
  }

}
@media (min-width: 768px) {
  .load-coin-shadow-middle {
    width: 120px;
    height: 97px;
  }
}