@media (min-width: 320px) {
  .load-coin-shadow-small {
    width: 90px;
    height: 78px;
  }
}
@media (min-width: 768px) {
  .load-coin-shadow-small {
    width: 117px;
    height: 94px;
  }
}
