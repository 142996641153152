@import "~scss/utils/_var";

.cointy-block {
  background: $cBgPrimary;
  border-radius: 20px;
  position: relative;

  &__wrapper {
    height: 100%;
  }

  &__title-span {
    color: $cSecondary;
  }

  &__text-top {
    margin-top: 30px;
  }

  &__text-bottom {
    margin-top: 30px;
  }

  &__grid-wrapper {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  &__grid-img {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(4.98deg);
  }

  &__info-content {
    position: relative;
    background-color: $cSecondary;
    display: flex;
    align-items: center;
    border-radius: 22px;
    z-index: 99;
  }

  &__info-content-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  &__coins-img {
    position: absolute;
    z-index: 99;
  }

  &__target {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 95%;
    left: 0;
  }
}

@media (min-width: 320px) {
  .cointy-block {
    margin-top: 80px;
    height: auto;

    &__info-content-wrapper {
      width: 210px;
    }

    &__info-wrapper {
      width: 100%;
      display: flex !important;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 80px;
    }

    &__grid-img {
      width: 854.69px;
      height: 757.65px;
    }

    &__coins-img {
      top: calc(100% - 50px);
      left: calc(50% - 73px);
      width: 137px;
      height: 122px;
    }

    &__info-content {
      width: 248px;
      height: 280px;
    }

    &__text-content {
      margin-top: 50px;
      margin-left: 0;
      margin-right: 0;
    }

    &__lock-icon {
      width: 32px;
      height: 32px;
    }

    &__user-icon {
      width: 32px;
      height: 32px;
    }

    &__mobile-icon {
      width: 32px;
      height: 32px;
    }

    &__dialog-icon {
      width: 32px;
      height: 32px;
    }
  }
}
@media (min-width: 768px) {
  .cointy-block {
    margin-top: 150px;
    height: 811px;

    &__info-content-wrapper {
      width: 250px;
    }

    &__info-wrapper {
      display: flex !important;
      justify-content: center;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__info-content {
      width: 450px;
      height: 347px;
    }

    &__text-content {
      margin-top: 0;
      margin-left: 40px;
      margin-right: 40px;
    }

    &__grid-img {
      width: 854.69px;
      height: 757.65px;
    }

    &__coins-img {
      top: 735px;
      left: -20px;
      width: 136px;
      height: 116.64px;
    }

    &__lock-icon {
      width: 36px;
      height: 36px;
    }

    &__user-icon {
      width: 36px;
      height: 36px;
    }

    &__mobile-icon {
      width: 36px;
      height: 36px;
    }

    &__dialog-icon {
      width: 36px;
      height: 36px;
    }
  }
}
@media (min-width: 992px) {
  .cointy-block {
    margin-top: 150px;
    height: 811px;

    &__info-content-wrapper {
      width: 250px;
    }

    &__info-wrapper {
      display: flex !important;
      justify-content: center;
    }

    &__info-content {
      width: 450px;
      height: 347px;
    }

    &__text-content {
      margin-left: 40px;
      margin-right: 40px;
    }

    &__grid-img {
      width: 854.69px;
      height: 757.65px;
    }

    &__coins-img {
      top: 735px;
      left: -20px;
      width: 136px;
      height: 116.64px;
    }

    &__lock-icon {
      width: 36px;
      height: 36px;
    }

    &__user-icon {
      width: 36px;
      height: 36px;
    }

    &__mobile-icon {
      width: 36px;
      height: 36px;
    }

    &__dialog-icon {
      width: 36px;
      height: 36px;
    }
  }
}
@media (min-width: 1200px) {
  .cointy-block {
    height: 578px;
    margin-top: 200px;

    &__info-wrapper {
      display: flex;
      justify-content: center;
    }

    &__lock-icon {
      width: 40px;
      height: 40px;
    }

    &__user-icon {
      width: 40px;
      height: 40px;
    }

    &__mobile-icon {
      width: 40px;
      height: 40px;
    }

    &__dialog-icon {
      width: 40px;
      height: 40px;
    }
  }
}
@media (min-width: 1440px) {
  .cointy-block {
    height: 578px;
    margin-top: 200px;

    &__info-content-wrapper {
      width: 250px;
    }

    &__info-wrapper {
      display: block;
    }

    &__info-content {
      width: 100%;
      height: 100%;
    }

    &__text-content {
      margin-left: 190px;
      margin-right: 0;
    }

    &__info-content {
      width: 473px;
      height: 438px;
      left: 90px;
    }

    &__grid-img {
      width: 854.69px;
      height: 757.65px;
    }

    &__coins-img {
      top: 450px;
      left: -55px;
      width: 136px;
      height: 108px;
    }

    &__lock-icon {
      width: 40px;
      height: 40px;
    }

    &__user-icon {
      width: 40px;
      height: 40px;
    }

    &__mobile-icon {
      width: 40px;
      height: 40px;
    }

    &__dialog-icon {
      width: 40px;
      height: 40px;
    }
  }
}
@media (min-width: 1700px) {
  .cointy-block {
    height: 625px;
    margin-top: 250px;

    &__info-content-wrapper {
      width: 250px;
    }

    &__info-wrapper {
      display: block;
    }

    &__info-content {
      width: 100%;
      height: 100%;
    }

    &__text-content {
      margin-left: 190px;
      margin-right: 0;
    }

    &__info-content {
      width: 525px;
      height: 464px;
      left: 150px;
    }

    &__grid-img {
      width: 854.69px;
      height: 757.65px;
    }

    &__coins-img {
      top: 470px;
      left: -90px;
      width: 179px;
      height: 142.01px;
    }

    &__lock-icon {
      width: 44px;
      height: 44px;
    }

    &__user-icon {
      width: 44px;
      height: 44px;
    }

    &__mobile-icon {
      width: 44px;
      height: 44px;
    }

    &__dialog-icon {
      width: 44px;
      height: 44px;
    }
  }
}
