.export-address{
  &__text{
    font-size: (14rem / 16);
    line-height: (22rem / 16);
    &-address{
      font-weight: 500;
    }
    &_mt{
      margin-top: 32px;
    }
  }
  &__label{
    display: block;
    font-size: (14rem / 16);
    line-height: (22rem / 16);
    margin-bottom: 8px;
  }
  &__input{
    margin-right: 16px !important;
  }
  &__input-wrap{
    display: flex;
    align-items: center;
  }
  &__radio{
    margin-bottom: 24px !important;
  }
}
