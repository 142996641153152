.cab-home{
  width: 100%;
  &__bottom{
    margin-top: 25px;
    display: flex;
    @media only screen and (max-width: 1300px) {
     margin-top: 18px;
    }
  }
  &__title{
    font-size: (16rem / 16);
    line-height: (24rem / 16);
    color: #332E54;
    margin: 0;
  }
}
.home-card{
  background: #fff;
  border-radius: 2px;
  flex: 1;
  &_portfolio{
    margin-right: 13px !important;
  }
  &_transactions{
    margin-left: 13px !important;
    @media only screen and (max-width: 1300px) {
      margin-left: 0 !important;
    }

  }
  &__header{
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #F3F2F5;
    .ant-btn{
      height: initial;
      padding: 0px;
      &:hover, &:active{
        border-color: transparent;
        opacity: .8;
      }
    }
    h1{
      margin: 0;
    }
  }
  &__body{
    padding-bottom: 7px;
  }
  &__error{
    padding: 24px 0;
  }
}
.transactions-details{
  max-width: 400px !important;
}
