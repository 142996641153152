@import "~scss/utils/_var";

.favorites-star {
  cursor: pointer;

  &:hover {
    path {
      fill: rgba($cSecondary, .7);
    }
  }
}