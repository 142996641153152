@import "~scss/utils/_mixins";

.logo {
  display: flex;
  align-items: center;
  position: relative;
  width: 250px;
  @media only screen and (max-width: 450px) {
    width: initial;
  }

  &__title {
    position: absolute;
    top: 4px;
  }

  &__link {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 320px) {
  .logo {
    &__icon {
      margin-right: 8px;
    }

    &__title {
      left: 40px;
      display: block;
    }

    &__title.hide {
      display: none;
    }
  }
}
@media (min-width: 450px) {
  .logo {
    &__title {
      left: 40px;
      display: block;
    }
  }
}
@media (min-width: 768px) {
  .logo {
    &__icon {
      margin-right: 14px;
    }

    &__title.hide {
      display: block;
    }

    &__title {
      left: 50px;
      display: block;
    }
  }
}
@media (min-width: 992px) {
  .logo {
    &__icon {
      margin-right: 14px;
    }
  }
}
@media (min-width: 1200px) {
  .logo {
    &__icon {
      margin-right: 14px;
    }

    &__title {
      left: 50px;
    }
  }
}
@media (min-width: 1440px) {
  .logo {
    &__icon {
      margin-right: 14px;
    }
  }

  &__title {
    left: 50px;
  }
}
@media (min-width: 1600px) {
  .logo {
    &__icon {
      margin-right: 16px;
    }
  }

  &__title {
    left: 50px;
  }
}
