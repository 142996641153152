@import "../../configComponents/parentComponent/creator";

@import "vars";
@import "maker";

.ant-picker {

  &-focused {
    border-color: $cSecondary;
    box-shadow: 0 0 0 2px rgba($cSecondary, .1);
  }

  &-header-view button:hover {
    color: $cSecondary;
  }


  .ant-picker-active-bar,
  &-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  &-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: $cSecondary;
  }

  &-cell-range-hover-end:after,
  &-cell-range-hover-start:after,
  &-cell-range-hover:after,
  &:hover, .ant-picker-focused,
  &-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $cSecondary !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $cSecondary;
}

