@import "~scss/utils/_var";

.portfolio {
  &__export-icon{
    margin-right: 9px;
  }
  &__export-button{
    background: transparent;
    box-shadow: none;
    border: none;
  }
  &__copy-btn{
    padding: 4px 8px !important;
    &_margin{
      margin-right: 16px;
    }
  }
  &__balance {
    width: 100%;
    height: 412px;
    background-color: $cDefault;
    border: 1px solid #EBEDF1;
    border-radius: 2px;

    &__content {
      display: flex;
      height: calc(100% - 56px);

      &__chart {
        width: 100%;
        padding: 29px 24px;
        overflow: hidden;
      }
    }

    &__header {
      padding: 16px 24px;
      box-shadow: inset 0px -1px 0px #F3F2F5;
    }


    &__info {
      width: 374px;
      padding: 24px;
      border-right: 1px solid #F3F2F5;

      &_count {
        margin-top: 7px;
      }

      &_item {
        margin-top: 30px;
      }

      &_title {
        margin-bottom: 24px;
      }

      &_btn {
        margin-top: 40px;
      }
    }
  }
}
