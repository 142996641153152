.header-notification {
  &__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-btn{
      padding: 0;
      border: none;
      &:hover{
        border: none;
        opacity: .8;
      }
      &:disabled{
        color: #554CC2;
        opacity: .8;
        background-color: transparent;
        &:hover{
          color: #554CC2;
          opacity: .8;
          background-color: transparent;
        }
      }
    }
  }
  &__empty{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    min-height: 200px;
  }
  &__icon{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    &_info{
      background: #e6f7ff;
    }
  }
  &_wrapper {
    padding-top: 5px !important;
    width: 400px;
    z-index: 100;
    h3,h4{
      text-transform: capitalize;
    }

    .ant-popover-title {
      padding: 16px 24px;
    }

    .ant-popover-inner-content{
      padding: 0;
    }
    .ant-list-item{
      padding: 12px 16px;
    }

    .ant-popover-arrow {
      display: none;
    }
  }

}

