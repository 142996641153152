@import "../../configComponents/parentComponent/creator";

@import "vars";
@import "maker";

.base-table{

  .ant-table {
    color: $cTable;
    transition: .3s;
  }
  &__icon-wrap{
    display: flex;
    align-items: center;
    color: #332E54;
  }
  &__icon{
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .ant-table-thead > tr > th{
    border-top: 1px solid #F3F2F5;
  }
  .ant-table-tbody tr td{
    color: #332E54;
  }
  .ant-table-thead > tr > th,
  .ant-table-summary > tr > td {
    background: #FBFBFC;
    border-radius: 0;
    border-bottom: 0;
  }
  &.base-table__vertical.base-table__send-assets{
    p{
      margin-bottom: 0;
    }
    .ant-table-thead tr th{
      color: #524D6E;
      font-weight: 400;
    }
    .ant-table-tbody tr td,
    .ant-table-thead tr th{
      display: inline-flex;
      align-items: center;
    }
    .ant-table-tbody tr td,
    .ant-table-thead tr th{
      height: 100px;
      padding: 16px !important;
    }
  }
  &.base-table__vertical{
    colgroup{
      display: none;
    }
    table{
      display: flex;
    }
    .ant-table-tbody tr td{
      padding: 27px 24px !important;
      border-right: 1px solid #F3F2F5;
      text-align: left !important;
      text-transform: capitalize;
      &:first-child{
        border-top: 1px solid #F3F2F5 !important;
      }
    }
    .ant-table-thead tr th{
      padding: 27px 24px !important;
      border-left: 1px solid #F3F2F5;
      border-right: 1px solid #F3F2F5;
      border-bottom: 0 !important;
      &:last-child{
        border-bottom: 1px solid #F3F2F5 !important;
      }
    }
    .ant-table-thead tr th,
    .ant-table-tbody td{
      width: 100%;
    }
    .ant-table-tbody{
      flex: 1;
    }
    .ant-table-thead tr,
    .ant-table-tbody tr{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .ant-table-tbody tr:hover{
      td{
        background: #ffffff;
      }
    }
    .transactions-type{
      margin-right: 14px;
    }
    .in > .transactions-type{
      transform: rotate(180deg);
    }
    .status{
      padding: 1px 8px;
      border-radius: 2px;
      font-size: (12rem / 16);
      line-height: (20rem / 16);
      text-transform: capitalize;
      &.processed{
        background-color: #E2FBEB;
        color: #358F54;
      }
      &.waiting{
        background-color: #FFF7E6;
        color: #FA8C16;
      }
      &.canceled{
        background-color: #FFF1F0;
        color: #D23E50;
      }
    }
  }
  &.base-table__transactions{
    @media only screen and (max-width: 1300px) {
      .ant-table-tbody > tr > td{
        font-size: (12rem / 16);
      }
    }
    .type{
      display: flex;
      align-items: center;
    }
    .amount p{
      white-space: nowrap;
    }
    .transactions-type{
      margin-right: 14px;
    }
    .in > .transactions-type{
      transform: rotate(180deg);
    }
    .status{
      padding: 1px 8px;
      border-radius: 2px;
      font-size: (12rem / 16);
      line-height: (20rem / 16);
      text-transform: capitalize;
      &.processed{
        background-color: #E2FBEB;
        color: #358F54;
      }
      &.waiting{
        background-color: #FFF7E6;
        color: #FA8C16;
      }
      &.canceled{
        background-color: #FFF1F0;
        color: #D23E50;
      }
    }
    .ant-table-tbody > tr:hover{
      cursor: pointer;
    }
    .ant-table-tbody > tr > td{
      text-transform: capitalize;
      &:first-child{
        color: #7A7790;
      }
    }
    .ant-table-thead > tr > th{
      padding: 9px 25px;
      border-top: 0;
      border-bottom: 1px solid #F3F2F5;
    }
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      padding: 9px 25px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      &:nth-child(3){
        text-align: right !important;
      }
    }
    .ant-table-tbody > tr:nth-child(5) td{
      border-bottom: 0;
    }
  }
  &.base-table__favorites{
    .anticon.anticon-menu svg{
      fill: #524D6E;
    }
    &_list{
      .ant-table-thead tr th:nth-child(3),
      .ant-table-thead tr th:nth-child(4),
      .ant-table-thead tr th:nth-child(5),
      .ant-table-tbody tr td:nth-child(3),
      .ant-table-tbody tr td:nth-child(4),
      .ant-table-tbody tr td:nth-child(5){
        text-align: right;
      }
    }
    &_available{
      .ant-table-thead tr th:nth-child(2),
      .ant-table-thead tr th:nth-child(3),
      .ant-table-thead tr th:nth-child(4),
      .ant-table-tbody tr td:nth-child(2),
      .ant-table-tbody tr td:nth-child(3),
      .ant-table-tbody tr td:nth-child(4){
        text-align: right;
      }
    }
    .ant-table-thead tr th:last-child{
      text-align: center;
    }
    &.hide-drag{
      .drag-visible > .anticon{
        display: none;
      }
    }
    .ant-table-thead > tr > th{
      border-bottom: 1px solid #F3F2F5;
    }
  }
  &.base-table__portfolio{
    //tbody tr:last-child{
    //  td{
    //    border-bottom: 0;
    //  }
    //}
    @media only screen and (max-width: 1300px) {
      .ant-table-tbody > tr > td{
        font-size: (12rem / 16);
      }
    }
    .ant-table-tbody > tr > td:nth-child(2){
      white-space: nowrap;
    }
    .ant-table-thead > tr > th{
      padding: 9px 25px;
      border-top: 0;
      border-bottom: 1px solid #F3F2F5;
      white-space: nowrap;
    }
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      padding: 8px 25px;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4){
        text-align: right !important;
      }
    }
    .ant-table-tbody > tr:nth-child(5) td{
      border-bottom: 0;
    }
  }
  &.base-table__wallets{
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      &:nth-child(2),
      &:nth-child(3){
        text-align: right !important;
      }
    }
  }
  &.base-table__portfolio-wallet,
  &.base-table__addresses{
    .ant-table-thead > tr > th{
      border-bottom: 1px solid #F3F2F5;
    }
  }
  &.base-table__portfolio-correlation{
    .ant-table-tbody > tr > td:first-child{
      display: flex;
    }
  }
  &.base-table__transactions-table,
  &.base-table__activity,
  &.base-table__saved-address{
    .ant-table-thead > tr > th{
      border-bottom: 1px solid #F3F2F5;
    }
    .ant-table-tbody > tr > td{
      padding: 12px 16px;
    }
  }

  &.base-table__activity{
    .ant-table-tbody > tr > td:nth-child(4){
      font-size: (12rem / 16);
    }
  }

  .ant-table-thead > tr > th {
    color: $cTableHeader;
    transition: .3s;
  }

  .ant-pagination-item-active {
    border-color: $cSecondary;

    a {
      color: $cSecondary;
    }
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $cSecondary;

    a {
      color: $cSecondary;
    }
  }

  .ant-pagination-prev:not(.ant-pagination-disabled):hover .ant-pagination-item-link,
  .ant-pagination-next:not(.ant-pagination-disabled):hover .ant-pagination-item-link {
    border-color: $cSecondary;
    color: $cSecondary;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: $cSecondary;
  }

  .ant-select-selection-item {
    padding-left: 15px !important;
    padding-right: 35px !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $cSecondary;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 2px rgba($cSecondary, .1);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: $cSecondary;
    box-shadow: 0 0 2px rgba($cSecondary, .1);
  }

  .ant-spin-dot-item {
    background-color: $cSecondary;
  }

  .ant-table-body {
    overflow-y: auto !important;
  }
  .ant-table-column-sorter-down.active,
  .ant-table-column-sorter-up.active {
    color: $cSecondary;
  }

  .ant-table-row-expand-icon:focus,
  .ant-table-row-expand-icon:hover {
    color: $cSecondary;
  }
}
