@import "../../configComponents/parentComponent/creator";

@import "vars";
@import "maker";

.c-skeleton{

  &__input {
    width: 320px;
    margin-bottom: 32px;

    &_label {
      width: 100px;
      height: 20px !important;
      margin-bottom: 10px;
    }

    &_field {
      width: 100%;
      height: 35px;
    }
  }
}
