.portfolio-currency {
  display: flex;
  margin-left: 15px;

  &_item {
    &:not(:last-child){
      padding-right: 56px;
    }

    &_title {
      margin-bottom: 7px;
    }
  }
}
