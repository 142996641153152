@import "~scss/utils/_var";

.card-news {
  height: 100%;

  &__title{
    font-size: (22rem / 16);
    line-height: (27rem / 16);
    color: $cPrimary;
    @media only screen and (max-width: 1440px) {
      font-size: (20rem / 16);
      line-height: (30rem / 16);
    }
    @media only screen and (max-width: 768px) {
      font-size: (18rem / 16);
      line-height: (22rem / 16);
    }
    @media only screen and (max-width: 576px) {
      font-size: (18rem / 16);
      line-height: (22rem / 16);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    border-radius: 22px;
    transition: .5s;
    padding: 20px;
    height: 100%;
    &:hover {
      box-shadow: 0 4px 48px rgba(104, 96, 160, 0.14);
      transition: .3s;
    }
    @media only screen and (max-width: 576px) {
      padding: 0 20px;
    }
    .ant-image{
      display: block;
      border-radius: 14px;
      padding-top: 63.4%;
      overflow: hidden;
      border-radius: 14px;
      .ant-image-img{
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        width: initial;
        max-width: 150%;
        transform: translate(-50%, -50%);
      }
    }

  }

  &__full {
    display: flex;
    .card-news__title{
      font-size: (28rem / 16);
      line-height: (36rem / 16);
      @media only screen and (max-width: 1440px) {
        font-size: (24rem / 16);
        line-height: (29rem / 16);
      }
      @media only screen and (max-width: 768px) {
        font-size: (20rem / 16);
        line-height: (24rem / 16);
      }
      @media only screen and (max-width: 576px) {
        font-size: (18rem / 16);
        line-height: (22rem / 16);
      }
    }
    .ant-image {
      display: block;
      border-radius: 14px;
      overflow: hidden;
      border-radius: 14px;
      padding-top: 30%;
      .ant-image-img{
        position: absolute;
        top: 50%;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        width: initial;
        max-width: 150%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    box-orient: vertical;
  }
  &__link{
    &:hover{
      color: $cSecondary;
      p {
        color: $cSecondary;
      }
    }
  }
}

@media (min-width: 320px) {
  .card-news {
    padding: 15px 0;

    &__full {
      flex-direction: column;
      .ant-image{
        width: 100%;
      }
    }

    &__content-full {
      width: 100%;
      align-self: self-start;
      margin-left: 0;
    }

    &__text {
      margin: 8px 0 10px 0;
    }
  }
}
@media (min-width: 768px) {
  .card-news {
    padding: 16px;

    &__full {
      flex-direction: row;
      .ant-image {
        width: 50%;
        .ant-image-img{
          width: 100%;
        }
      }
    }
    &__content-full {
      width: 50%;
      align-self: center;
      margin-left: 30px;
    }

    &__text, &__link {
      display: -webkit-box;
    }
  }
}
@media (min-width: 1200px) {
  .card-news {

  }
}
@media (min-width: 1440px) {
  .card-news {
    &__title {
      width: 100%;
    }

    &__text {
      margin: 16px 0 20px 0;
    }
  }
}
@media (min-width: 1600px) {
  .card-news {
    &__title {
      width: 70%;
    }
  }
}
