@media (min-width: 320px) {
  .load-coin-middle {
    width: 53px;
    height: 58px;
  }
}
@media (min-width: 768px) {
  .load-coin-middle {
    width: 70px;
    height: 76px;
  }
}