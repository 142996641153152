@import "~scss/utils/_var";

.receive {
  position: relative;

  &__title-span {
    color: $cSecondary;
  }

  &__poster-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__poster {
    background-size: cover;
    background-position: center;
  }

  &__bubble-wrapper {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
  }

  &__target {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 95%;
    left: 0;
  }

}

@media (min-width: 320px) {
  .receive {
    margin-top: 80px;

    &__text-top {
      margin-top: 22px;
    }

    &__poster-wrapper {
      margin-top: 35px;
      margin-bottom: 100px;
    }

    &__poster {
      width: 280px;
      height: 147px;
    }

    &__bubble-wrapper {
      top: 175px;
      left: calc(50% - 110px);

      .bubble.top-right:after {
        top: calc(-100% + 55px);
      }
    }

    &__bubble {
      width: 240px;
    }
  }
}
@media (min-width: 500px) {
  .receive {
    &__poster {
      width: 430px;
      height: 226px;
    }

    &__bubble-wrapper {
      top: 250px;
    }
  }
}
@media (min-width: 600px) {
  .receive {
    &__poster {
      width: 530px;
      height: 274px;
    }

    &__bubble-wrapper {
      top: 310px;
    }
  }
}
@media (min-width: 768px) {
  .receive {
    margin-top: 120px;

    &__text-top {
      margin-top: 24px;
    }

    &__poster-wrapper {
      margin-top: 40px;
      margin-bottom: 0;
    }

    &__poster {
      width: 690px;
      height: 340px;
    }

    &__bubble-wrapper {
      top: 75px;
      left: 160px;
    }

    &__bubble {
      width: 320px;
    }
  }
}
@media (min-width: 992px) {
  .receive {
    margin-top: 160px;

    &__text-top {
      margin-top: 28px;
    }

    &__poster-wrapper {
      margin-top: 50px;
    }

    &__poster {
      width: 924px;
      height: 485px;
    }

    &__bubble-wrapper {
      top: 140px;
      left: 370px;
    }

    &__bubble {
      width: 320px;
    }
  }
}
@media (min-width: 1200px) {
  .receive {
    margin-top: 160px;

    &__text-top {
      margin-top: 28px;
    }

    &__poster-wrapper {
      margin-top: 50px;
    }

    &__poster {
      width: 924px;
      height: 485px;
    }

    &__bubble-wrapper {
      top: 140px;
      left: 470px;
    }

    &__bubble {
      width: 320px;
    }
  }
}
@media (min-width: 1440px) {
  .receive {
    margin-top: 160px;

    &__text-top {
      margin-top: 28px;
    }

    &__poster-wrapper {
      margin-top: 50px;
    }

    &__poster {
      width: 924px;
      height: 485px;
    }

    &__bubble-wrapper {
      top: 145px;
      left: 530px;
    }

    &__bubble {
      width: 320px;
    }
  }
}
@media (min-width: 1600px) {
  .receive {
    margin-top: 220px;

    &__text-top {
      margin-top: 28px;
    }

    &__poster-wrapper {
      margin-top: 60px;
    }

    &__poster {
      width: 1324px;
      height: 695px;
    }

    &__bubble-wrapper {
      top: 215px;
      left: 730px;
    }

    &__bubble {
      width: 320px;
    }
  }
}