@import "~scss/utils/_mixins";

.container {
  margin-right: auto;
  margin-left: auto;
  width: calc(100% - 40px);
  min-width: 320px;
  @include styleAllScreen(width, 1490px, 1310px, 1170px, 970px, 690px);
  @media only screen and (max-width: 576px) {
    width: 100%;
    min-width: initial;
  }
}
