@import "~scss/utils/_var";

.header-profile-action {
  &__action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;

    &__currency {
      display: flex;

      &__icon {
        width: 60px;
        height: 60px;
        margin-right: 22px;
      }

      &__wallet {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &__title {
          margin-top: 8px;
        }

        &__address {
          display: flex;
          align-items: center;

          &__text {
            margin-right: 24px;

            span {
              color: $cPrimary;
            }
          }

          &__copy {
            margin-right: 8px;
            padding-right: 5px !important;
          }

          &__qr {
            margin-left: 8px;
          }
        }
      }
    }

    &__btn {

      &__favorite {
        margin-right: 8px;
      }

      &__assets {
        margin-left: 8px;
      }
    }
  }
}
