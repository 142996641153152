.send-assets{
  &__balance{
    background: #FBFBFC;
    border: 1px dashed #EBEDF1;
    border-radius: 2px;
    padding: 12px;
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-btn-default{
      padding: 0 4px;
      &:hover,
      &:active{
        opacity: .8;
        border-color: transparent;
      }
    }
  }
  &__input-amount{
    padding: 4px 50px 4px 11px !important;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  &__type{
    margin-bottom: 28px;
  }
  &__fee{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    &-text{
      font-size: (14rem / 16);
      line-height: (22rem / 16);
    }
    &-val{
      font-weight: 500;
    }
  }
  &__label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 11px;
  }
  &__text{
    font-size: (14rme / 16);
    line-height: (22rem / 16);
    color: #332E54;
    margin: 0;
    &-value{
      font-weight: 500;
    }
  }
  &__amount-row{
    display: flex;
    margin-top: 32px;
    align-items: flex-start;
    & > .ant-row{
      flex: 1;
    }
  }
  &__amount-fiat{
    margin-top: 36px !important;
  }
  &__equal{
    margin: 40px 12px 0;
  }
  &__address-row{
    display: flex;
    align-items: flex-end;
    margin: 8px 0;
    .anticon{
      margin: 0 !important;
    }
    .ant-btn{
      padding: 4px 8px;
    }
    & > .ant-form-item{
      flex: 1;
    }
  }
  &__button{
    margin-bottom: 24px;
  }
  &__submit{
    font-size: (14rem / 16);
    line-height: (22rem / 16);
    font-weight: 400;
    height: initial !important;
    padding: 5px;
    border-radius: 2px !important;
  }
  &__table {
    &-desc {
      font-size: (14rem / 16);
      line-height: (22rem /16);
      color: #7A7790;
      margin-bottom: 0;
    }
    &-val {
      color: #332E54;
      word-break: break-word;
    }
    &-amount{
      font-weight: 500;
      margin-bottom: 0;
    }
    &-fiat{
      font-weight: 400;
    }
  }
  &__modal{
    &-title{
      display: block;
      color: #332E54;
      margin-bottom: 12px;
    }
    &-text{
      font-size: (14rem / 16);
      line-height: (22rem / 16);
    }
  }
  &__submits{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select-recipient__button{
      margin-top: 0;
    }
    .select-recipient__button,
    .button-wrapper{
      width: calc(50% - 10px);
    }
  }
}
