.m-auto {
  margin: auto !important;
}

.scrollbar-x{
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.scrollbar-y{
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.visible {
  display: block;

  &.flex {
    display: flex;
  }

  &.md {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &.lg {
    @media (min-width: 992px) {
      display: none;
    }
  }

  &.xl {
    @media (min-width: 1200px) {
      display: none;
    }
  }

  &.xxl {
    @media (min-width: 1600px) {
      display: none;
    }
  }
}

.Hide {
  display: none !important;
}

.hide {
  display: none;

  &.md {
    @media (min-width: 768px) {
      display: block;

      &.flex {
        display: flex;
      }
    }
  }

  &.lg {
    @media (min-width: 992px) {
      display: block;

      &.flex {
        display: flex;
      }
    }
  }

  &.xl {
    @media (min-width: 1200px) {
      display: block;

      &.flex {
        display: flex;
      }
    }
  }

  &.xxl {
    @media (min-width: 1600px) {
      display: block;

      &.flex {
        display: flex;
      }
    }
  }
}
