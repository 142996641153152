.chart-tooltip{
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(51, 46, 84, 0.15);
  border-radius: 2px;
  padding: 10px 12px;
  &__label,
  &__price{
    font-size: 14px;
    line-height: 22px;
  }
  &__label{
    color: #524D6E;
    margin-bottom: 4px;
  }
  &__price{
    color: #332E54;
    margin: 0;
  }
  .ant-radio-group-small .ant-radio-button-wrapper {
    padding: 0 10px !important;
  }
}
.chart{
  &__responsive-chart{
    overflow: hidden;
  }
  &__responsive-wrap{
    position: relative;
    width: 100%;
    height: 100%;
  }
  &__node-error{
    padding: 0 0 40px;
  }
  &__loading{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background-color: rgba(255,255,255,.5);
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
      z-index: 100;
    }
  }
  &__spin{
      color: #554CC2 !important;
      & > .anticon{
        font-size: 24px !important;
      }
  }
}
