// Color
$cPrimary: #332E54;
$cSecondary: #554CC2;
$cDefault: #fff;
$cGray: #807d7d;
$cGrayLight: #595674;
$cError: #D23E50;
$cSuccess: #4BA98E;
$cLightPurple: #8b899e;
$cPurple: #524D6E;
$cTextGray: #7A7790;
$cDark: #000;
$cWhite: #fff;
$cGreen: #358F54;
// Background
$cBgPrimary: #F7F4FD;
$cBgDefault: #FFF;
// Cabinet
$cBorderPrimary: #EBEDF1;
$cBgSelectMenu: #F4F4FF;
$cBgCabinet: #E5E5E5;
// Table
$cTableHeader: $cPurple;
$cTable: $cPrimary;
$cTableTagSuccessBg: #E2FBEB;
$cTableTagSuccessText: $cGreen;
$cTableTagWarningBg: #FFF7E6;
$cTableTagWarningText: #FA8C16;
$cTableTagErrorBg: #FFF1F0;
$cTableTagErrorText: #D23E50;
// Btn
$cBtnPrimaryHover: #7369e4;