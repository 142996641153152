@import "~scss/utils/_var";

.price-chart{
  border-radius: 2px;
  &__title{

  }
  & > .ant-card-body{
    padding: 0;
  }
  &__header{
    padding: 0 25px;
    border-bottom: 1px solid #F3F2F5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-tabs-tab-active p{
      color: #554CC2;
    }
  }
  &__tabs{
    .ant-tabs-tab{
      padding: 0;
    }
    & > .ant-tabs-nav{
      margin: 0 !important;
    }
    .ant-skeleton-button{
      margin-left: 12px;
    }
  }
  &__body{
    display: flex;
  }
  &__chart{
    padding: 24px 14px 24px 24px;
    border-right: 1px solid #F3F2F5;
    flex: 2;
    overflow: hidden;
  }
  &__chart-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 28px;
    h1{
      margin: 0;
    }
    .ant-radio-group-small .ant-radio-button-wrapper {
      padding: 0 10px !important;
    }
    .ant-radio-group-small .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: $cSecondary;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: 0 0 2px rgba($cSecondary, .1) !important;
    }
  }
  &__list{
    list-style-type: none;
    display: flex;
    margin-bottom: 0;
    padding: 0;
  }
  &__item{
    margin-right: 32px;
  }
  &__icon{
    margin-right: 12px;
  }
  &__link{
    display: inline-flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 2px solid transparent;
    &.active{
      color: #554CC2;
      border-color: #554CC2;
    }
  }
  &__overview{
    flex: 1;
  }
  &__overview{
    padding: 24px;
  }
}
