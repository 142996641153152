@import "src/scss/utils/var";
.news-page{
  &__wrap{
      & > .container{
          @media only screen and (max-width: 600px) {
            padding:  0 20px;
          }
      }
  }
  &__breadcrumb{
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    & > span{
      display: flex;
      align-items: center;
    }
    p{
      white-space: nowrap;
    }
  }
  &__link{
    &:hover{
      color: $cSecondary !important;
    }
  }
  &__head-area{
    position: relative;
    background-color: transparent;
    &::after{
      content: '';
      display: block;
      width: 100%;
      background: #F7F4FD;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__caption{
    margin-top: 36px;
  }
  &__date-wrap{
    display: flex;
    justify-content: center;
    margin: 48px 0;
  }
  &__date{
    display: block;
    position: relative;
    padding-left: 30px;
    &::before{
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      background-color: $cLightPurple;
      border-radius: 100%;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__img{
    border-radius: 22px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1600px) {
  .news-page{
    &__head-area{
      &::after{
        padding-top: 38.7%;
      }
    }
  }
}
@media only screen and (min-width: 320px) {
  .news-page{
    &__head-area{
      padding-top: 32px;
      &::after{
       height: 512px;
      }
    }
    &__date-wrap{
      margin: 22px 0 24px;
    }
    &__caption{
      margin-top: 26px;
    }
    &__img{
      margin-bottom: 28px;
    }
  }
}
@media only screen and (min-width: 768px) {
  .news-page{
    &__head-area{
      padding-top: 44px;
      &::after{
        height: 798px;
      }
    }
    &__caption{
      margin-top: 44px;
    }
    &__date-wrap{
      margin: 40px 0 28px;
    }
    .text-22{
      font-size: 20px;
      line-height: 30px;
    }
    .title-66 {
      font-size: 60px;
      line-height: 72px;
    }
  }
}
@media only screen and (min-width: 1440px) {
  .news-page{
    &__head-area{
      padding-top: 56px;
      &::after{
        height: 743px;
      }
    }
    &__caption{
      margin-top: 20px;
    }
    &__date-wrap{
      margin: 22px 0 36px;
    }
  }
}
@media only screen and (min-width: 1600px) {
  .news-page{
    .title-66{
      font-size: 66px;
      line-height: 79px;
    }
    .text-22{
      font-size: 22px;
      line-height: 27px;
    }
    &__head-area{
      &::after{
        height: 741px;
      }
    }
    &__caption{
      margin-top: 36px;
    }
    &__date-wrap{
      margin: 48px 0;
    }
  }
}
