@import "~scss/utils/_var";

.cabinet {
  height: 100vh;

  &__content-wrapper {
    background-color: $cBgCabinet;
  }

  &__content {
    display: flex;
    overflow: auto;
    padding: 0;
    margin: 0;

    &__wrapper {
      display: flex;
      flex-direction: column;
      flex: auto;
      overflow-x: hidden;
      &.transactions{
        & > div{
          padding: 0;
        }
      }

      &_main {
        flex-grow: 1;
        margin: 24px 24px 0;
        max-width: calc(100vw - 256px);
        transition: max-width .19s;
        border: 1px solid #EBEDF1;
        border-radius: 2px;
        @media only screen and (max-width: 1300px) {
          margin: 18px 18px 0;
        }

        &.width {
          background-color: $cDefault;
          //padding: 24px;
        }
        &.transparent {
          background-color: transparent;
          border: none;
          border-radius: 0;
        }
      }

      &_main.close_sider {
        max-width: calc(100vw - 127px);
        transition: max-width .19s;
      }
    }
  }

  &__footer {
    padding: 35px 50px 16px 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
