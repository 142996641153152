@import "~scss/utils/_var";
@import "~scss/utils/mixins";

.transaction-filter {


  .ant-drawer-body {
    overflow: hidden;
  }

  &__body {
    width: 400px;
  }

  &__option {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      margin-right: 8px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      @include rowGap(10px)
    }
  }
}