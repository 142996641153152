.header-profile-info {
  margin-bottom: 30px;
  display: flex;

  &_item {
    margin-right: 60px;

    &_title {
      margin-bottom: 7px;
    }
  }
}