@media (min-width: 320px) {
  .load-coin-small {
    width: 49px;
    height: 60px;
  }
}
@media (min-width: 768px) {
  .load-coin-small {
    width: 64px;
    height: 79px;
  }
}