@import "~scss/utils/_var";
@import "~scss/utils/_mixins";

.sign {
  display: flex;
  flex-direction: column;

  .form-group-item:not(.form-group-item_left) {
    @include styleAllScreen(margin-left, 12px, 12px, 12px, 12px, 12px);
  }

  .form-group-item:not(.form-group-item_right) {
    @include styleAllScreen(margin-right, 12px, 12px, 12px, 12px, 12px);
  }

  .ant-form-item {
    @include styleAllScreen(margin-bottom, 30px, 24px, 30px, 30px, 30px, 30px);
  }

  &__btn {
    @include styleAllScreen(margin, 32px 0, 27px 0, 32px 0, 32px 0, 32px 0, 32px 0);
  }

  &__form {
    @include styleAllScreen(margin, 32px 0, 27px 0, 32px 0, 32px 0, 32px 0, 32px 0, true);
  }

  &__strongt {
    color: $cSuccess;
  }

  &__weak {
    color: $cError;
  }

  &__checkbox-wrapper {
    display: flex;

    @media (min-width: 320px) {
      margin-top: 28px;
    }
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  &__checkbox-label {
    margin-left: 12px;

    @include styleAllScreen(width, 370px, 370px, 370px, 370px, 370px, 300px);
  }

  &__loader-icon {
    font-size: 30px;
    color: $cSecondary !important;
  }

  &__text {
    @include styleAllScreen(margin-top, 18px, 14px, 14px, 14px, 14px, 16px);

    @media (min-width: 320px) {
      align-self: flex-start;
    }
  }

  &__wrapper  {
    overflow-y: auto;
    scrollbar-width: none;
    @include styleAllScreen(margin-top, 185px, 100px, 125px, 135px, 135px, 88px);
    @include styleAllScreen(width, 445px, 445px, 445px, 455px, 455px, 100%);
    @include styleAllScreen(align-self,  flex-start,  flex-start,  flex-start, center, center, center);

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
