.check-3d-icon.primary {

  path:nth-child(1) {
    fill: #554CC2;
  }

  path:nth-child(2) {
    fill: #4B42B0;
  }

  path:nth-child(3) {
    fill: #6063C6;
  }

  path:nth-child(4){
    fill: #6063C6;
  }

  path:nth-child(5) {
    fill: #7A7DE4;
  }
}
