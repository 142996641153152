.icon-nav {
  width: 100%;
  height: 100%;

  &__top {
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    top: 13px;
    left: 9px;
    background-color: white;
    transform: rotate(0deg);
  }

  &__middle {
    position: absolute;
    width: 16px;
    height: 2px;
    border-radius: 1px;
    top: 18px;
    left: 13px;
    background-color: white;
  }

  &__bottom {
    position: absolute;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    top: 23px;
    left: 9px;
    background-color: white;
    transform: rotate(0deg);
  }
}