@import "~scss/utils/_var";

.mob-mav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  display: none;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: $cBgPrimary;

  &__row {
    margin-top: 100px;
  }

  &.show {
    display: block;
    z-index: 998;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    @media only screen and (orientation: landscape) {
      align-items: flex-start;
    }
  }

  &__link-item {
    text-align: center;
  }
}

@media (min-width: 320px) {
  .mob-mav {

    &__auth {
      margin-top: 80px;
    }

    &__signup {
      width: 122px !important;
      height: 40px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }

    &__social {
      margin-top: 40px;
    }

    &__signin {
      padding-right: 20px;
    }
  }
}
@media (min-width: 768px) {
  .mob-mav {

    &__auth {
      margin-top: 160px;
    }

    &__social {
      margin-top: 100px;
    }

    &__signin {
      padding-right: 30px;
    }

    &__signup {
      width: 181px !important;
      height: 57px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
    }
  }
}
