@import "~scss/utils/_mixins";

.footer {
  width: 100%;

  &__subscribe-text {
    margin-top: 10px;
  }

  &__date {
    margin-top: 20px;
  }

  &__social-top {
    margin-top: 40px;
  }

  &__social-bottom {
    order: 4;
    margin-top: 22px;
  }

  &__subscribe-text {
    @include styleAllScreen(margin-top, 12px, 12px, 12px, 12px, 12px)
  }
}

@media (min-width: 320px) {
  .footer {
    .container {
      margin-bottom: 20px;
    }

    &__info {
      order: 1;
    }

    &__nav {
      order: 3;
      margin-top: 12px;

      &_row {
        @include styleAllScreen(padding-top, 12px, 12px, 12px, 12px, 20px)
      }
    }

    &__subscribe {
      order: 2;
      margin-top: 40px;
    }

    &__wrapper {
      margin-top: 10px;
    }
  }
}
@media (min-width: 768px) {
  .footer {
    .container {
      margin-bottom: 30px;
    }

    &__info {
      order: 1;
    }

    &__nav {
      order: 3;
      margin-top: 10px;
    }

    &__subscribe {
      order: 2;
      margin-top: 0;
    }

    &__wrapper {
      margin-top: 20px;
    }
  }
}
@media (min-width: 992px) {
  .footer {
    .container {
      margin-bottom: 30px;
    }

    &__info {
      order: 1;
    }

    &__nav {
      order: 2;
      margin-top: 0;
    }

    &__subscribe {
      order: 3;
    }

    &_date {
      margin-top: 20px;
    }

    &__social-top {
      margin-top: 24px;
    }

    &__wrapper {
      margin-top: 20px;
    }
  }
}
@media (min-width: 1200px) {
  .footer {
    .container {
      margin-bottom: 40px;
    }

    &__info {
      order: 1;
    }

    &__nav {
      order: 2;
    }

    &__subscribe {
      order: 3;
    }
  }

  &__wrapper {
    margin-top: 40px;
  }
}
@media (min-width: 1440px) {
  .footer {
    .container {
      margin-bottom: 40px;
    }

    &__info {
      order: 1;
    }

    &__nav {
      order: 2;
    }

    &__subscribe {
      order: 3;
    }

    &_date {
      margin-top: 20px;
    }

    &__social-top {
      margin-top: 40px;
    }

    &__wrapper {
      margin-top: 40px;
    }
  }
}
@media (min-width: 1600px) {
  .footer {
    .container {
      margin-bottom: 50px;
    }

    &.absolute {
      position: absolute;
      left: 0;
    }

    &__info {
      order: 1;
    }

    &__nav {
      order: 2;
    }

    &__subscribe {
      order: 3;
    }

    &_date {
      margin-top: 20px;
    }

    &__social-top {
      margin-top: 40px;
    }

    &__wrapper {
      margin-top: 40px;
    }
  }
}