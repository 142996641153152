@media (min-width: 320px) {
  .load-coin-shadow-large {
    width: 95px;
    height: 79px;
  }
}
@media (min-width: 768px) {
  .load-coin-shadow-large {
    width: 124px;
    height: 99px;
  }
}