@import "~scss/utils/_var";

.header-cab {
  background: $cBgDefault !important;
  padding: 0 !important;
  height: auto !important;

  &__notification {
    display: flex !important;
    justify-content: center;
    height: 100%;
    align-items: center;
    background-color: transparent;
    transition: .3s;

    &_active {
      background-color: $cBgSelectMenu;
      transition: .3s;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 64px;
    padding: 0 24px;
    box-shadow: inset 0px -1px 0px $cBorderPrimary
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }

  &__page-header.show {
    padding: 24px 24px 0 24px;
    border-bottom: 1px solid $cBorderPrimary;
  }

  &__page-header > div {
    line-height: 0;
  }

  &__item {
    margin: 0 14px !important;
    color: $cPrimary;
    transition: .3s;
  }

  &__item:hover {
    color: $cSecondary;
    transition: .3s;
  }

  &__item.header-cab__select {
    margin-left: 30px !important;
  }

  &__avatar {
    background-color: $cBgPrimary !important;
  }

  &__avatar-icon {
    color: $cSecondary !important;
  }

  &__profile {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_active {
      pointer-events: none;
    }
  }

  &__profile:hover &__name {
    color: $cSecondary !important;
    transition: .3s;
  }
  
  &__icon {
    cursor: pointer;
  }

  &__select {

    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      border-right: 5px;
      background-color: $cSecondary !important;
    }

    .ant-select-arrow {
      color: white;
    }

    .ant-select-selection-item {
      color: white !important;
      padding: 0 16px !important;
    }
  }


  &__def-select {
    display: none !important;
  }

  &__option {

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }

  &__option-arrow {
    margin-right: 10px;
  }

  &__option-arrow.revers {
    transform: rotate(180deg);
  }
}
