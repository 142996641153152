@import "~scss/utils/_var";

.button-wrapper {
  position: relative;
  top: 0;
  left: 0;
}

.button {
  position: relative;
  outline: none;
  border: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  transition: .3s;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &.rad-small {
    border-radius: 2px;
  }
  &.rad-norma {
    border-radius: 6px;
  }


  &.left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.normal {
    width: 150px;
    height: 50px;
  }

  &.full {
    width: 100%;
    height: 50px;
  }

  &.icon {
    width: 40px;
    height: 40px;
  }

  // Color - start
  // ## error
  &.error.text {
    color: $cError;

    a {
      color: $cError;
    }
  }
  &.error.text:hover:not(.icon):not([disabled]),
  &.error.text:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cError;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.error.contained {
    color: $cBgDefault;
    background-color: $cError;
    transition: .3s;

    a {
      color: $cBgDefault;
    }
  }
  &.error.contained:hover:not(.icon):not([disabled]),
  &.error.contained:focus:not(.icon):not([disabled]) {
    background-color: rgba($cError, .8);
    transition: .3s;
  }
  &.error.outlined {
    color: $cError;
    border: 1px solid $cError;

    a {
      color: $cError;
    }
  }
  &.error.outlined:hover:not(.icon):not([disabled]),
  &.error.outlined:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cError;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.error.text[disabled],
  &.error.outlined[disabled],
  &.error.contained[disabled], {
    border-color: transparent;
    color:  rgba($cDefault, .3);
    background-color: rgba($cError, .3);
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  // ## gray
  &.gray.text {
    color: $cGray;

    a {
      color: $cGray;
    }
  }
  &.gray.text:hover:not(.icon):not([disabled]),
  &.gray.text:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cGray;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.gray.contained {
    color: $cBgDefault;
    background-color: $cGray;
    transition: .3s;

    a {
      color: $cBgDefault;
    }
  }
  &.gray.contained:hover:not(.icon):not([disabled]),
  &.gray.contained:focus:not(.icon):not([disabled]) {
    background-color: rgba($cGray, .8);
    transition: .3s;
  }
  &.gray.outlined {
    color: $cGray;
    border: 1px solid $cGray;

    a {
      color: $cGray;
    }
  }
  &.gray.outlined:hover:not(.icon):not([disabled]),
  &.gray.outlined:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cGray;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.gray.text[disabled],
  &.gray.outlined[disabled],
  &.gray.contained[disabled], {
    border-color: transparent;
    color:  rgba($cDefault, .3);
    background-color: rgba($cGray, .3);
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  // ## secondary
  &.secondary.text {
    color: $cGray;

    a {
      color: $cSecondary;
    }
  }
  &.secondary.text:hover:not(.icon):not([disabled]),
  &.secondary.text:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: #655BE3;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.secondary.text:active:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: #493FBF;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.secondary.contained {
    color: $cBgDefault;
    background-color: $cSecondary;
    transition: .3s;

    a {
      color: $cBgDefault;
    }
  }
  &.secondary.contained:hover:not(.icon):not([disabled]),
  &.secondary.contained:focus:not(.icon):not([disabled]) {
    background-color: #655BE3;
    transition: .3s;
  }
  &.secondary.contained:active:not(.icon):not([disabled]) {
    background-color: rgba(#493FBF, .8);
    transition: .3s;
  }
  &.secondary.outlined {
    color: $cSecondary;
    border: 1px solid $cSecondary;

    a {
      color: $cSecondary;
    }
  }
  &.secondary.outlined:hover:not(.icon):not([disabled]),
  &.secondary.outlined:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: #655BE3;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.secondary.outlined:hover:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: #493FBF;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.secondary.text[disabled],
  &.secondary.outlined[disabled],
  &.secondary.contained[disabled], {
    border-color: transparent;
    color:  rgba($cDefault, .3);
    background-color: rgba($cSecondary, .3);
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  // ## primary
  &.primary.text {
    color: $cGray;

    a {
      color: $cPrimary;
    }
  }
  &.primary.text:hover:not(.icon):not([disabled]),
  &.primary.text:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cPrimary;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.primary.contained {
    color: $cBgDefault;
    background-color: $cPrimary;
    transition: .3s;

    a {
      color: $cBgDefault;
    }
  }
  &.primary.contained:hover:not(.icon):not([disabled]),
  &.primary.contained:focus:not(.icon):not([disabled]) {
    background-color: rgba($cPrimary, .8);
    transition: .3s;
  }
  &.primary.outlined {
    color: $cPrimary;
    border: 1px solid $cPrimary;

    a {
      color: $cPrimary;
    }
  }
  &.primary.outlined:hover:not(.icon):not([disabled]),
  &.primary.outlined:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cPrimary;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.primary.text[disabled],
  &.primary.outlined[disabled],
  &.primary.contained[disabled], {
    border-color: transparent;
    color:  rgba($cDefault, .3);
    background-color: rgba($cPrimary, .3);
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
 // ## default
  &.default.text {
    color: $cSecondary;

    a {
      color: $cSecondary;
    }
  }
  &.default.text:hover:not(.icon):not([disabled]),
  &.default.text:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cSecondary;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.default.contained {
    background-color: $cBgDefault;
    color: $cSecondary;

    a {
      color: $cSecondary;
    }
  }
  &.default.contained:hover:not(.icon):not([disabled]),
  &.default.contained:focus:not(.icon):not([disabled]) {
    background-color: rgba($cBgDefault, .9);
    transition: .3s;
  }
  &.default.outlined {
    color: $cSecondary;
    border: 1px solid $cSecondary;

    a {
      color: $cSecondary;
    }
  }
  &.default.outlined:hover:not(.icon):not([disabled]),
  &.default.outlined:focus:not(.icon):not([disabled]) {
    color: $cDefault;
    background-color: $cSecondary;
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  &.default.text[disabled],
  &.default.outlined[disabled],
  &.default.contained[disabled], {
    border-color: transparent;
    color:  rgba($cDefault, .3);
    background-color: rgba($cSecondary, .3);
    transition: .3s;

    a {
      color: $cDefault;
    }
  }
  // Color - end





  &__load-wrapper {
    position: absolute;
    padding: 0 5%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 100;
  }

  &__load-wrapper.center {
    justify-content: center;
  }
  &__load-wrapper.start {
    justify-content: flex-start;
  }
  &__load-wrapper.end {
    justify-content: flex-end;
  }
}